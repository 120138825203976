import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';
import { Control } from 'ol/control';

class GoogleLogoControl extends Control {
  constructor() {
    const element = document.createElement('img');
    element.style.pointerEvents = 'none';
    element.style.position = 'absolute';
    element.style.bottom = '5px';
    element.style.left = '5px';
    element.src = 'https://developers.google.com/static/maps/documentation/images/google_on_white.png';
    super({
      element: element,
    });
  }
}

export default function GoogleMapsLogoControl() {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const googleLogoControl = new GoogleLogoControl();

    map.addControl(googleLogoControl);

    return () => {
      map.removeControl(googleLogoControl);
    };
  }, [map]);

  return null;
}
