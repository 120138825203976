import moment from 'moment';
import * as ol from 'ol';
import { Feature } from 'ol';
import FontSymbol from 'ol-ext/style/FontSymbol';
import { Coordinate } from 'ol/coordinate';
import * as Extent from 'ol/extent';
import { FeatureLike } from 'ol/Feature';
import Circle from 'ol/geom/Circle';
import Point from 'ol/geom/Point';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import { fromLonLat } from 'ol/proj';
import { default as Vector, default as VectorSource } from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useEffect, useMemo, useState } from 'react';
import {
  JobWithDeviceAssignments,
  UnitMeasurement,
  UnitSystem,
  UnitType,
  useWeedByCoordinatesLazyQuery,
} from '../../../graphql/generated';
import {
  Controls,
  FullScreenControl,
  Interactions,
  Layers,
  Map,
  ModifyInteraction,
  Overlays,
  TileLayer,
  ZoomControl,
} from '../../../shared/components/maps/Map';
import VectorLayer from '../../../shared/components/maps/Map/Layers/VectorLayer';
import WebGLLayer from '../../../shared/components/maps/Map/Layers/WebGLLayer';
import GridTile from '../../../shared/layout/tiles/GridTile';
import {
  convertDistance,
  ConvertDistanceMode,
  updateCoordinatesFromLonLat,
} from '../../../shared/utilities/OpenLayersUtilities';
import { DateInputFormat } from '../../../shared/utilities/TimeUtilities';
import WeedMapSearchFilter from '../Components/WeedMapSearchFilter';
import WeedOverlay from '../Components/WeedOverlay';
import { defaults } from 'ol/interaction/defaults';
import { calculateInitialZoom } from '../../../shared/components/maps/Map/Controls/ZoomControl';
import env from '@beam-australia/react-env';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import convert from 'convert-units';
import { GetConvertUnit } from '../../../shared/utilities/UnitConversionUtilities';
import Google from 'ol/source/Google';

const RadiusMarkerClassName = 'RadiusMarker';
export const JobPropertyName = 'j';
export const LogPropertyName = 'l';
export const DeviceAssignmentPropertyName = 'd';

export const WeedsMapUnitSystemToUnitType: { [key in UnitSystem]: UnitType } = {
  [UnitSystem.Imperial]: UnitType.Mile,
  [UnitSystem.Metric]: UnitType.Kilometer,
};

export const WeedsMapUnitSystemDefaultValue: { [key in UnitSystem]: number } = {
  [UnitSystem.Imperial]: 7,
  [UnitSystem.Metric]: 10,
};

export default function WeedsMap() {
  const [map, setMap] = useState<ol.Map>();

  const [fetch, { data }] = useWeedByCoordinatesLazyQuery({
    fetchPolicy: 'no-cache',
  });
  /**
   * In the format [longitude, latitude]
   */

  const { unitSystem } = useSelectedOrganisationIdStore();
  const lengthUnit = WeedsMapUnitSystemToUnitType[unitSystem];

  const [centre, setCentre] = useState<Coordinate>(fromLonLat([0, 0]));
  const [radius, setRadius] = useState<number>(WeedsMapUnitSystemDefaultValue[unitSystem]);

  // Manage these so the map can update the form and vice versa and maintain the form's inputs
  const [selectedWeedId, setSelectedWeedId] = useState<string>('');
  const [startTime, setStartTime] = useState<string>(moment().startOf('d').format(DateInputFormat));
  const [endTime, setEndTime] = useState<string>(moment().endOf('d').format(DateInputFormat));
  const [selectedFeature, setSelectedFeature] = useState<FeatureLike>();

  /**
   * Update the center of the map to be the user's location if they've provided it
   */
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCentre(fromLonLat([position.coords.longitude, position.coords.latitude]));
    });
  }, []);

  /**
   * A WebGL layer representing found weeds
   */
  const weedLayer = useMemo(() => {
    if (data && data.weedByCoordinates) {
      return GetWeedLayer(data.weedByCoordinates as JobWithDeviceAssignments[]);
    }

    return undefined;
  }, [data, data?.weedByCoordinates]);

  // Double clicking the map updates the search area
  map?.on('dblclick', (ev) => {
    setCentre(ev.coordinate);
  });

  // Single click on weed features displays an overlay with contextual information
  map?.on('singleclick', function (ev) {
    map.forEachFeatureAtPixel(ev.pixel, (feature) => {
      // If there's a feature where the user clicked and it represents a spray log
      if (feature && feature.get(LogPropertyName) && feature !== selectedFeature) {
        setSelectedFeature(feature);
        return true;
      }
      // If the user has selected off a log hide existing overlay
      else if (selectedFeature) {
        setSelectedFeature(undefined);
      }
    });
  });

  // Updates the mouse cursor if it is above a weed feature
  map?.on('pointermove', function (e) {
    const hit = map.hasFeatureAtPixel(e.pixel, {
      layerFilter: (layer) => RadiusMarkerClassName !== layer.getClassName(),
    });
    (map.getTarget() as HTMLElement).style.cursor = hit ? 'pointer' : '';
  });

  /**
   * A vector layer source that display the center of the selected area and it's radius.
   * Only updates when either {@link centre}, {@link map}, and {@link radius} are updated.
   */
  const searchAreaInfoSource = useMemo(() => {
    if (map && radius) {
      return getSearchAreaInfoSource(centre, map, { value: radius, unit: lengthUnit });
    }
  }, [centre, map, radius]);

  const defaultInteractions = useMemo(() => defaults({ doubleClickZoom: false }), []);
  const radiusInMeters = convert(radius).from(GetConvertUnit(lengthUnit)).to('m');

  const initialZoom = calculateInitialZoom(map, 12, () => {
    const circle = new Circle(centre, radiusInMeters);
    return circle.getExtent();
  });

  return (
    <GridTile>
      <div className="grid grid-cols-3 md:grid-cols-6">
        <div className="col-span-4 order-last md:order-first">
          <Map
            viewOptions={{ center: centre, zoom: initialZoom }}
            mapCallback={setMap}
            interactions={defaultInteractions}
          >
            <Overlays>
              <WeedOverlay feature={selectedFeature} />
            </Overlays>
            <Interactions>
              {searchAreaInfoSource && (
                <ModifyInteraction
                  options={{ source: searchAreaInfoSource }}
                  onModifyEnd={(event) => {
                    // The job infoSource's first index is the radius circle
                    const feature = event.features.getArray()[0];
                    // The extent will be the bounds of the circle
                    const extent = feature.getGeometry()?.getExtent() as Extent.Extent;
                    const newCenter = Extent.getCenter(extent);
                    const newRadiusInMeters =
                      convertDistance(
                        ConvertDistanceMode.Map_Units_To_Metres,
                        // This component wouldn't exist if the map wasn't set so:
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        map!,
                        Extent.getHeight(extent), // Height is the diameter of the circle
                        newCenter,
                      ) / 2;

                    const viewUnits = convert(newRadiusInMeters).from('m').to(GetConvertUnit(lengthUnit));
                    setRadius(viewUnits);
                    setCentre(newCenter);
                  }}
                />
              )}
            </Interactions>
            <Layers>
              <TileLayer
                source={
                  new Google({
                    key: env('GOOGLE_MAP_KEY'),
                    mapType: 'satellite',
                    layerTypes: ['layerRoadmap'],
                  })
                }
              />
              {searchAreaInfoSource && (
                <VectorLayer
                  className={RadiusMarkerClassName}
                  source={searchAreaInfoSource as VectorSource<FeatureLike>}
                />
              )}
              {weedLayer && <WebGLLayer layer={weedLayer} />}
            </Layers>
            <Controls>
              <FullScreenControl />
              <ZoomControl />
            </Controls>
          </Map>
        </div>
        <div className="col-span-3 md:col-span-2 p-4">
          <WeedMapSearchFilter
            //Unfortunate state management
            weedId={selectedWeedId}
            coordinates={centre}
            radius={radius}
            startTime={startTime}
            endTime={endTime}
            setRadiusCallback={(radius) => setRadius(radius)}
            setCoordinateCallback={(val) => setCentre(updateCoordinatesFromLonLat(centre, val))}
            setWeedIdCallback={setSelectedWeedId}
            setStartTimeCallback={setStartTime}
            setEndTimeCallback={setEndTime}
            onPlaceChangedCallback={(autocomplete) => {
              const place = autocomplete.getPlace();
              if (place && place.geometry && place.geometry.location) {
                const location = place.geometry.location;
                // Set the center from the selected place's coordinates and propagate down to the form
                setCentre(fromLonLat([location.lng(), location.lat()]));
              }
            }}
            onSubmitCallback={(values) => {
              fetch({
                variables: {
                  input: {
                    ...values,
                    radius: { value: values.radius, unit: lengthUnit },
                  },
                },
              });
            }}
          />
        </div>
      </div>
    </GridTile>
  );
}

/**
 * The style of the radius circle
 */
const radiusCircleStyle = new Style({
  fill: new Fill({ color: [0, 0, 0, 0] }),
  stroke: new Stroke({
    color: 'red',
    width: 2,
  }),
});

/**
 * The style of the center marker.
 */
const centreMarkerStyle = new Style({
  image: new FontSymbol({
    form: 'marker',
    radius: 15,
    offsetY: -15,
    fill: new Fill({
      color: 'red',
    }),
    stroke: new Stroke({
      color: 'black',
      width: 2,
    }),
  }),
});

/**
 * Creates a vector source to display the current search area on the map.
 * @param coordinate Center of the search area.
 * @param map The map being used.
 * @param radius The radius of the search area.
 * @returns A vector source with features to demonstrate the search area
 */
function getSearchAreaInfoSource(coordinate: Coordinate, map: ol.Map, radius: UnitMeasurement) {
  const radiusMeters = convert(radius.value).from(GetConvertUnit(radius.unit)).to('m');

  const radiusMapUnits = convertDistance(ConvertDistanceMode.Metres_To_Map_Units, map, radiusMeters, coordinate);

  // Create the radius circle and style it
  const radiusCircle = new Feature(new Circle(coordinate, radiusMapUnits));
  radiusCircle.setStyle(radiusCircleStyle);

  // Create the center marker and style it
  const centreMarker = new Feature({ geometry: new Point(coordinate) });
  centreMarker.setStyle(centreMarkerStyle);

  // Source and vector layer
  return new VectorSource({
    features: [radiusCircle, centreMarker] as Feature[],
  });
}

/**
 * Creates a WebGL points layer to show where weeds were found.
 * @param jobSprayLogPairs Pairs containing jobs and their associated spray logs containing the searched for weed.
 * @returns A WebGL points layer containing the instances of weeds found.
 */
function GetWeedLayer(jobs: Array<JobWithDeviceAssignments>) {
  const features: FeatureLike[] = jobs.flatMap((job) =>
    job.deviceAssignments.flatMap((da) =>
      da.sprayLogs.map(
        (log) =>
          new Feature({
            geometry: new Point(fromLonLat([log.c.x, log.c.y])),
            // Attach the job and log to the feature so it can be retrieved later for the overlay
            [JobPropertyName]: job,
            [LogPropertyName]: log,
            [DeviceAssignmentPropertyName]: da,
          }),
      ),
    ),
  );

  return new WebGLPointsLayer({
    source: new Vector({
      features: features,
    }),
    style: {
      'circle-radius': 7.5,
      'circle-fill-color': 'lawnGreen',
    },
  });
}
