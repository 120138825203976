import env from '@beam-australia/react-env';
import * as ol from 'ol';
import FontSymbol from 'ol-ext/style/FontSymbol';
import { Coordinate } from 'ol/coordinate';
import * as Extent from 'ol/extent';
import Feature, { FeatureLike } from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Heatmap from 'ol/layer/Heatmap';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import { fromLonLat } from 'ol/proj';
import { default as Vector, default as VectorSource } from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useMemo, useState } from 'react';
import { DeviceAssignment, Job, SprayLogDto, UnitMeasurement, Weed } from '../../../../graphql/generated';
import {
  Controls,
  FullScreenControl,
  Layers,
  Map,
  TileLayer,
  ZoomControl,
} from '../../../../shared/components/maps/Map';
import { calculateInitialZoom } from '../../../../shared/components/maps/Map/Controls/ZoomControl';
import VectorLayer from '../../../../shared/components/maps/Map/Layers/VectorLayer';
import { OperatorPathWebGLLayer } from '../../../../shared/components/maps/Map/Layers/WebGLVectorLayer';
import Overlays from '../../../../shared/components/maps/Map/Overlays/Overlays';
import { weedValueToButtonsPressed } from '../../utilities/WeedButtonUtilities';
import ExportControl, { DeviceAssignmentLayersType } from './ExportControl';
import FilterControl from './FilterControl';
import './FullScreenControl.scss';
import HeatmapLayers from './HeatmapLayers';
import Legend from './Legend';
import OperatorPathLayers from './OperatorPathLayers';
import LogOverlay from './SprayLogOverlay';
import WeedLayers from './WeedLayers';
import { Geometry } from 'ol/geom';
import Google from 'ol/source/Google';
import UserLocationLayer, { UserLocationClassName } from './UserLocationLayer';

export interface DeviceAssignmentLayerPair {
  deviceAssignment: DeviceAssignment;
  heatmap: Heatmap;
  operatorPath: OperatorPathWebGLLayer;
}

export interface WeedLayerPair {
  weed: Weed;
  layer: WebGLPointsLayer<VectorSource<FeatureLike>>;
}

interface TestMapProps {
  job: Job;
  callback: (map: ol.Map) => void;
}

export const weedColours = ['orange', 'blue', 'green'];

const WeedLayerClassName = 'WeedLayer';
const JobInfoLayerClassName = 'JobInfoLayer';
export const SprayLogPropertyName = 'sprayLog';
export const WeedPropertyName = 'weed';

const ONE_KM = 1000;
const DEFAULT_ZOOM_RADIUS = ONE_KM * 33;

export default function JobMap({ job, callback }: TestMapProps) {
  const [center] = useState(fromLonLat(job.location ? [job.location.longitude, job.location.latitude] : [0, 0]));
  const [map, setMap] = useState<ol.Map>();
  const [selectedFeature, setSelectedFeature] = useState<FeatureLike>();

  const updateMapState = (map: ol.Map) => {
    setMap(map);
  };

  const { weedLayerPairs, deviceAssignmentLayerPairs, maxSprayed, extent } = useMemo(
    () => getAdditionalLayers(job, center),
    [job],
  );

  map?.on('singleclick', function (ev) {
    const feature = map.forEachFeatureAtPixel(ev.pixel, (feature) => feature);
    // If there's a feature where the user clicked and it represents a spray log
    if (feature && feature.get(SprayLogPropertyName)) {
      setSelectedFeature(feature);
    }
    // If the user has selected off a log hide existing overlay
    else if (selectedFeature) {
      setSelectedFeature(undefined);
    }
  });

  map?.on('pointermove', function (e) {
    const hit = map.hasFeatureAtPixel(e.pixel, {
      layerFilter: (layer) =>
        ![JobInfoLayerClassName, WeedLayerClassName, UserLocationClassName].includes(layer.getClassName()),
    });
    (map.getTarget() as HTMLElement).style.cursor = hit ? 'pointer' : '';
  });

  map?.on('moveend', function () {
    callback(map);
  });

  let mapMaxZoom = { ...extent };
  const size = Extent.getSize(mapMaxZoom);

  if (size[0] === 0 && size[1] === 0) {
    mapMaxZoom = Extent.buffer(mapMaxZoom, DEFAULT_ZOOM_RADIUS);
  } else {
    Extent.scaleFromCenter(mapMaxZoom, 10);
  }

  const initialZoom = calculateInitialZoom(map, 17, () => {
    return extent;
  });

  return job.location ? (
    <Map
      viewOptions={{
        center,
        zoom: initialZoom,
        extent: mapMaxZoom,
        showFullExtent: true,
        smoothExtentConstraint: true,
      }}
      mapCallback={updateMapState}
    >
      <Overlays>
        <LogOverlay feature={selectedFeature} />
      </Overlays>
      <Layers>
        <TileLayer
          source={
            new Google({
              key: env('GOOGLE_MAP_KEY'),
              mapType: 'satellite',
              layerTypes: ['layerRoadmap'],
            })
          }
        />
        <UserLocationLayer />
        {job.location && (
          <VectorLayer source={getJobLocationMarker(center)} className={JobInfoLayerClassName} zIndex={10} />
        )}
        <HeatmapLayers deviceAssignments={deviceAssignmentLayerPairs} />
        <OperatorPathLayers deviceAssignments={deviceAssignmentLayerPairs} />
        <WeedLayers weeds={weedLayerPairs} />
      </Layers>
      <Controls>
        <FullScreenControl className="job-map-full-screen-control" />
        <ZoomControl />
        <FilterControl deviceAssignments={deviceAssignmentLayerPairs} weeds={weedLayerPairs} />
        <ExportControl job={job} />
        <Legend maxSprayed={maxSprayed} weeds={job.weedAssignments} />
      </Controls>
    </Map>
  ) : (
    <div className="flex flex-row justify-center h-full w-full p-8">Map unavailable - no location has been set.</div>
  );
}

function getJobLocationMarker(coordinate: Coordinate) {
  const iconFeature = new Feature({
    geometry: new Point(coordinate),
  });

  const iconStyle = new Style({
    image: new FontSymbol({
      form: 'marker',
      radius: 15,
      offsetY: -15,
      fill: new Fill({
        color: 'red',
      }),
      stroke: new Stroke({
        color: 'black',
        width: 2,
      }),
    }),
  });

  iconFeature.setStyle(iconStyle);

  return new VectorSource({
    features: [iconFeature] as FeatureLike[],
  });
}

function getAdditionalLayers(job: Job, center: Coordinate) {
  const weedLayerPairSources: { [key: number]: Feature<Point>[] } = {
    0: [],
    1: [],
    2: [],
  };

  let maxSprayed: UnitMeasurement | undefined = undefined;

  const extent: Extent.Extent = Extent.createOrUpdateFromCoordinate(center);

  const deviceAssignmentLayerPairs = job.deviceAssignments.map((deviceAssignment, index) => {
    const heatmapFeatures: Feature<Point>[] = [];
    const coordinates: Coordinate[] = [];

    for (let index = 0; index < deviceAssignment.sprayLogs.length; index++) {
      const sprayLog = deviceAssignment.sprayLogs[index];

      // Determine the max sprayed by a log
      if (!maxSprayed || sprayLog.v.value > maxSprayed.value) {
        maxSprayed = sprayLog.v;
      }
      const coordinate = fromLonLat([sprayLog.c.x, sprayLog.c.y]);

      //only include coordinates that aren't null island
      if (!(sprayLog.c.x === 0 && sprayLog.c.y === 0)) Extent.extendCoordinate(extent, coordinate);

      const point = new Point(coordinate);

      // Only add weeds where a weed is present with volume sprayed
      if (sprayLog.v.value > 0 && sprayLog.w !== 0) {
        const buttonsPressed = weedValueToButtonsPressed[sprayLog.w];
        for (let index = 0; index < buttonsPressed.length; index++) {
          const element = buttonsPressed[index];
          weedLayerPairSources[element - 1].push(
            new Feature({
              geometry: point,
              [SprayLogPropertyName]: sprayLog,
              [WeedPropertyName]: job.weedAssignments[element - 1],
            }),
          );
        }
      }

      heatmapFeatures.push(new Feature({ geometry: point, [SprayLogPropertyName]: sprayLog }));
      coordinates.push(coordinate);
    }

    const heatmap = new Heatmap({
      opacity: 0.5,
      radius: 15,
      blur: 15,
      source: new Vector<Feature<Geometry>>({ features: heatmapFeatures }),
      weight: (feature) => (feature.get(SprayLogPropertyName) as SprayLogDto).v.value / (maxSprayed?.value ?? 0),
      properties: {
        type: DeviceAssignmentLayersType,
        index: index,
      },
    });

    const operatorPath = new OperatorPathWebGLLayer({
      source: new VectorSource({ features: [new Feature({ geometry: new LineString(coordinates) })] }),
    });

    return { deviceAssignment, heatmap, operatorPath };
  });

  const weedLayerPairs: Array<WeedLayerPair> = job.weedAssignments.map((weed, index) => {
    const layer = new WebGLPointsLayer({
      className: WeedLayerClassName,
      source: new Vector<FeatureLike>({
        features: weedLayerPairSources[index],
      }),
      style: {
        'circle-radius': 7.5,
        'circle-fill-color': weedColours[index],
      },
    });

    return { weed: weed, layer: layer };
  });

  return { weedLayerPairs, deviceAssignmentLayerPairs, maxSprayed, extent };
}
