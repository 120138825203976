import { useEffect, useState } from 'react';
import VectorLayer from '../../../../shared/components/maps/Map/Layers/VectorLayer';
import { Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import { FeatureLike } from 'ol/Feature';
import { Point } from 'ol/geom';
import FontSymbol from 'ol-ext/style/FontSymbol';
import { Fill, Stroke, Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat } from 'ol/proj';

export const UserLocationClassName = 'UserLocation';

export default function UserLocationLayer() {
  const [location, setLocation] = useState<Coordinate | undefined>();

  useEffect(() => {
    navigator.geolocation.watchPosition(({ coords }) => {
      setLocation(fromLonLat([coords.longitude, coords.latitude]));
    });
  }, []);

  return location ? (
    <VectorLayer
      className={UserLocationClassName}
      source={
        new VectorSource<FeatureLike>({
          features: [getFeature(location)],
        })
      }
    />
  ) : null;
}

/**
 * The style of the center marker.
 */
const userLocationStyle = new Style({
  image: new FontSymbol({
    form: 'circle',
    radius: 10,
    fill: new Fill({
      color: 'blue',
    }),
    stroke: new Stroke({
      color: 'white',
      width: 2,
    }),
  }),
});

function getFeature(coordinate: Coordinate): FeatureLike {
  const feature = new Feature({ geometry: new Point(coordinate) });
  feature.setStyle(userLocationStyle);
  return feature;
}
