import { ReactNode } from 'react';
import GoogleMapsLogoControl from './GoogleLogoControl';

interface ControlsProps {
  children: ReactNode;
}

export default function Controls({ children }: ControlsProps) {
  return (
    <div className="controls">
      {
        <>
          {children}
          <GoogleMapsLogoControl />
        </>
      }
    </div>
  );
}
